import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import '../styles/global.css';

export default function OurCustomers() {
  return (
    <div className="bg-white drop-shadow-lg px-10 py-5 relative -top-12 rounded-lg">
      <h3 className="text-dark-blue text-3xl text-center font-semibold mb-5">
      Exclusive Partners
      </h3>
      <div className="grid grid-cols-3 gap-5 h-28">
  <div className="flex justify-center items-center">
    <StaticImage
      alt="Modern logo"
      src="../assets/logos/ML.png"
      objectFit="contain"
      
    
    />
  </div>
  <div className="flex justify-center items-center">
    <StaticImage
      alt="modernworking logo"
      src="../assets/logos/MW.png"
      objectFit="contain"
      
      
    />
  </div>
  <div className="flex justify-center items-center">
    <StaticImage
      src="../assets/logos/SV.png"
      alt="softversum logo"
      objectFit="contain"
      className="customer_logo"
    />
  </div>
</div>
    </div>
  );
}

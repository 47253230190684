import React from "react";
import { Helmet } from "react-helmet";

type LayoutType = {
  title: string;
  mobileStatusBarColor: string;
  children: JSX.Element[];
};

export default function Layout({
  title,
  mobileStatusBarColor,
  children,
}: LayoutType) {
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta lang="en" charSet="utf-8" />
        <meta name="theme-color" content={mobileStatusBarColor} />
        <meta
          name="description"
          content="Smith Klein Group is an experienced partner for business establishment. SKG is, located in Dubai, United Arab Emirates and has strong experience in business set."
        />
      </Helmet>
      {children}
    </div>
  );
}

import React from "react";
import Paragraph from "./Paragraph";
import Header from "./Header";
import { StaticImage } from "gatsby-plugin-image";

export default function About() {
  return (
    <div className="mb-10">
      <Header content="Who we are?" extraClass="text-center" />
      <div className="md:grid md:grid-cols-2 md:gap-5">
        <StaticImage
          alt="Man pointing to board, woman writing on notebook, view of UAE"
          src="../assets/images/about.png"
          className="w-full h-full"
        />
        <div>
          <h2 className="text-dark-blue text-4xl font-semibold mb-5">
            Experience is the basis for growing a solid idea to a fast scaling
            business.
          </h2>
          <Paragraph
            content="Smith Klein Group is an experienced
            partner for business establishment.
            We are a team of dynamic and skilled
            experts, who are passionate to share
            their experience. years of experiences
            in consultancy, business foundation,
            business operations and
            entrepreneurship, we are your trusted
            partner for guiding you throughout all
            phases of business establishment.
            Starting from the initial idea up to the
            scaling in the market. We as Smith
            Klein Group offer you specialized
            services to achieve all the benefits of
            establishing, growing and running a
            profitable company and life in UAE."
          />
        </div>
      </div>
    </div>
  );
}

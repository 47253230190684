import { StaticImage } from "gatsby-plugin-image";
import React, { useRef } from "react";
import Header from "./Header";
import Paragraph from "./Paragraph";

export default function Intro() {
  return (
    <div className="mb-10">
      <div className="md:grid md:grid-cols-2">
        <div className="my-auto">
          <Header content="Experience is the basis for growing a solid idea to a fast scaling business. " />
          <Paragraph content="Smith Klein Group is an experienced partner for business establishment. SKG is, located in Dubai, United Arab Emirates and has strong experience in business set-up in this region." />
          <Paragraph content="We are a team of dynamic and skilled experts, who are passionate to share their experience with entrepreneurs to build and grow their businesses in the United Arab Emirates." />
        </div>
        <StaticImage
          alt="%100 satisfaction, 100% company set up, 358 happy customer"
          src="../assets/images/intro.png"
          className="w-full h-full object-fill"
        />
      </div>
    </div>
  );
}

import React from "react";
import SKGTextLogo from "../assets/logos/skg-fulltext.svg";
import SKGLogo from "../assets/logos/skg.svg";
import Hamburger from "../assets/icons/hamburger.svg";

type NavbarType = {
  telephone: string;
  toggleNavbar: () => void;
  visible?: boolean;
};

export default function Navbar({
  toggleNavbar,
  telephone,
  visible = false,
}: NavbarType) {
  if (!visible) {
    return null;
  }
  return (
    <div className="bg-dark-blue sticky top-0 z-50">
      <div className="container h-20 mx-auto">
        <div className="block md:hidden pt-5 pb-2 px-5 h-full">
          <div className="flex justify-between items-center h-full">
            <SKGTextLogo className="h-full" />
            <Hamburger className="h-3" onClick={toggleNavbar} />
          </div>
        </div>
        <div className="hidden md:block h-full">
          <div className="flex justify-between items-center py-5 h-full">
            <div className="text-white text-sm font-bold space-x-5">
              <a href="#contact">Contact Us</a>
              <a href="#services">Services</a>
              <a href="#about">About Us</a>
            </div>
            <div className="h-12 w-12 relative -left-8">
              <SKGLogo className="h-full w-full" />
            </div>
            <a
              href={`tel:${telephone}`}
              className=" border text-sm rounded-full py-2 px-5 border-gray text-gray"
            >
              {telephone}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

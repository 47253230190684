import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import Left from "../assets/icons/left.svg";
import Right from "../assets/icons/right.svg";
import Header from "./Header";
import Paragraph from "./Paragraph";
import Separator from "./Separator";

type PickerType = {
  header: string;
  services: any[];
  toggleQuota: () => void;
};

function Picker({ header, services, toggleQuota }: PickerType) {
  const [selected, setSelected] = useState<number>(0);
  const { description, bulletPoints, title, id, subHeader, img, imgAlt } =
    services[selected];

  const image = getImage(img);

  const points =
    bulletPoints &&
    bulletPoints.map((point, index) => <li key={index}>{point}</li>);

  const subMenus = services.map((service, index) => (
    <p
      key={index}
      className={
        (index === selected ? "text-light-blue font-semibold" : "text-gray") +
        " cursor-pointer"
      }
      onClick={() => setSelected(index)}
    >
      {service.subHeader}
    </p>
  ));

  return (
    <div className="mb-10">
      <Header content={header} extraClass="text-center break-words" />
      <div className="flex flex-wrap justify-center mb-5 space-x-3 md:space-x-5">
        {subMenus}
      </div>
      <div className="bg-white drop-shadow-xl rounded-lg overflow-hidden">
        <GatsbyImage
          alt={imgAlt}
          image={image!}
          className="w-full h-48 object-cover"
        />
        <div className="p-5">
          <h2 className="mb-5 text-dark-blue text-2xl font-bold">{title}</h2>
          <Paragraph content={description!} />
          <ul className="mb-5 text-gray list-inside text-lg list-disc">
            {points}
          </ul>
          <div className="md:flex md:justify-between md:items-center">
            <button
              className="py-3 px-10 bg-[#00A6FB] text-white rounded-md mb-5 md:mb-0 shadow-button"
              onClick={toggleQuota}
            >
              Request a Quote
            </button>
            <div className="flex gap-20">
              <Left
                className={
                  (selected > 0 ? "cursor-pointer" : "cursor-default") + " h-6"
                }
                onClick={() => selected > 0 && setSelected(selected - 1)}
              />
              <Right
                className={
                  (selected + 1 < services.length
                    ? "cursor-pointer"
                    : "cursor-default") + " h-6"
                }
                onClick={() =>
                  selected + 1 < services.length && setSelected(selected + 1)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

type ServicePickerType = {
  toggleQuota: () => void;
};

export default function ServicePicker({ toggleQuota }: ServicePickerType) {
  const query = graphql`
    query {
      allBusinessJson {
        nodes {
          bulletPoints
          description
          img {
            childImageSharp {
              gatsbyImageData
            }
          }
          imgAlt
          id: jsonId
          subHeader
          title
        }
      }
      allResidencyJson {
        nodes {
          bulletPoints
          description
          img {
            childImageSharp {
              gatsbyImageData
            }
          }
          imgAlt
          id: jsonId
          subHeader
          title
        }
      }
      allOthersJson {
        nodes {
          description
          img {
            childImageSharp {
              gatsbyImageData
            }
          }
          imgAlt
          id: jsonId
          subHeader
          title
        }
      }
    }
  `;

  const response = useStaticQuery(query);

  const {
    allBusinessJson: { nodes: businessData },
    allResidencyJson: { nodes: residencyData },
    allOthersJson: { nodes: othersData },
  } = response;

  return (
    <div>
      <Picker
        header="Business Set-up"
        services={businessData}
        toggleQuota={toggleQuota}
      />
      <Separator />
      <Picker
        header="Residences & Visas"
        services={residencyData}
        toggleQuota={toggleQuota}
      />
      <Separator />
      <Picker
        header="Other Services"
        services={othersData}
        toggleQuota={toggleQuota}
      />
    </div>
  );
}

import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export default function Separator() {
  return (
    <div className="flex flex-row items-center justify-center space-x-5 mb-10 mx-auto">
      <div className="overflow-hidden h-12">
        <StaticImage
          src="../assets/images/logo.png"
          className="w-full h-full"
          alt=""
          objectFit="cover"
        />
      </div>
    </div>
  );
}

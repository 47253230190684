import React from "react";
import Close from "../assets/icons/close.svg";
import JotformEmbed from "react-jotform-embed";

type FormData = {
  fullName: string;
  email: string;
  phone: string;
  country: string;
  reason: "business" | "residency" | "other";
};

type QuotaType = {
  toggleQuota: () => void;
  visible?: boolean;
};

export default function Quota({ toggleQuota, visible = false }: QuotaType) {
  if (!visible) {
    return null;
  }
  return (
    <div className="fixed bg-black/40 inset-0 z-[51] overflow-y-auto overflow-x-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div className="relative overflow-hidden md:rounded-xl md:w-[600px] w-full bg-dark-blue ">
          <Close
            className="absolute cursor-pointer text-black top-5 right-5 w-4 h-4"
            onClick={toggleQuota}
          />
          <JotformEmbed src="https://form.jotform.com/220931780244049" />
        </div>
      </div>
    </div>
  );
}

import React from "react";
import SKGDownLogo from "../assets/logos/skg-down.svg";
import Location from "../assets/icons/location.svg";
import Mail from "../assets/icons/mail.svg";
import Phone from "../assets/icons/phone.svg";
import Linkedin from "../assets/icons/Linkedin.svg";
import Whatsapp from "../assets/icons/WhatsApp.svg";
import Instagram from "../assets/icons/Instagram.svg";

function About() {
  return (
    <div className="md:col-span-4 flex flex-col items-center">
      <SKGDownLogo className="w-full mb-5" />
      <p className="text-center text-sm">
        Smith Klein Group is an experienced partner for business establishment.
        We are a team of dynamic and skilled experts, who are passionate to
        share their experience.
      </p>
    </div>
  );
}
function SiteMap() {
  return (
    <div className="md:col-span-2">
      <h3 className="text-3xl font-semibold mb-10">Site Map</h3>
      <div className="flex flex-col gap-10 text-sm">
        <a href="#about">About Us</a>
        <a href="#services">Services</a>
        <a href="#who">Who We Are</a>
        <a href="#customers">Our Team</a>
      </div>
    </div>
  );
}

function GetInTouch() {
  return (
    <div className="md:col-start-7 md:col-span-4">
      <h3 className="text-3xl font-semibold mb-10">Get in touch with us</h3>
      <div className="flex flex-col gap-10 text-sm">
        <div className="flex items-center gap-10">
          <Location className="h-6 w-6" />
          <a href="https://goo.gl/maps/Kt8Dd2GYwRL8MqvX9">
            Office C101 The OPUS by Omniyat - Al Amal St - Business Bay - Dubai
          </a>
        </div>
        <div className="flex items-center gap-10">
          <Mail className="h-6 w-6" />
          <a href="mailto:connect@skg.ae">connect@skg.ae</a>
        </div>
        <div className="flex items-center gap-10">
          <Phone className="h-6 w-6" />
          <a href="tel:+971-56-878-2033">+971-56-878-2033</a>
        </div>
        <div className="flex gap-5">
          <div className="flex items-center gap-10">
            <a href="https://www.linkedin.com/company/skgae">
              <Linkedin className="h-6 w-6" />
            </a>
          </div>
          <div className="flex items-center gap-10">
            <a href="https://instagram.com/skg.ae?igshid=MzRlODBiNWFlZA==">
              <Instagram className="h-6 w-6" />
            </a>
          </div>
          <div className="flex items-center gap-10">
            <a href="http://wa.me/971568782033">
              <Whatsapp className="h-6 w-6" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Footer() {
  return (
    <div className="bg-dark-blue md:p-32 px-4 py-24">
      <div className="container">
        <div className="md:grid md:grid-cols-12 md:gap-10 text-white space-y-10 md:space-y-0">
          <About />
          <SiteMap />
          <GetInTouch />
        </div>
      </div>
    </div>
  );
}

import { graphql, PageProps, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import About from "../components/About";
import Contact from "../components/Contact";
import OurTeam from "../components/OurTeam";
import Footer from "../components/Footer";
import FullNavbarMobile from "../components/FullNavbarMobile";
import Heading from "../components/Heading";
import Intro from "../components/Intro";
import Layout from "../components/Layout";
import Navbar from "../components/Navbar";
import OurCustomers from "../components/OurCustomers";
import OurServices from "../components/OurServices";
import Quota from "../components/Quota";
import Separator from "../components/Separator";
import ServicePicker from "../components/ServicePicker";

export default function IndexPage({}: PageProps) {
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const [showQuota, setShowQuota] = useState<boolean>(false);

  const {
    site: {
      siteMetadata: { telephone, email, address, title, mobileStatusBarColor },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          telephone
          email
          address {
            label
            url
          }
          title
          mobileStatusBarColor
        }
      }
    }
  `);

  function toggleNavbar() {
    setShowMobileMenu(!showMobileMenu);
  }

  function toggleQuota() {
    setShowQuota(!showQuota);
  }

  return (
    <Layout title={title} mobileStatusBarColor={mobileStatusBarColor}>
      <Quota visible={showQuota} toggleQuota={toggleQuota} />
      <FullNavbarMobile
        visible={showMobileMenu}
        toggleNavbar={toggleNavbar}
        telephone={telephone}
      />
      {/* prettier-ignore */}
      <div className={showQuota || showMobileMenu ? "hidden md:block" : "block"} >
        <Navbar
          visible={!showMobileMenu}
          toggleNavbar={toggleNavbar}
          telephone={telephone}
        />
        <Heading />
        <div className="container">
          <OurCustomers />
          <Separator />
          <a id="about" className="relative -top-28" />
          <Intro />
        </div>
        <a id="services" className="relative -top-28" />
        <div className="container">
          <Separator />
          <OurServices />
          <Separator />
          <ServicePicker toggleQuota={toggleQuota} />
          <Separator />
          <a id="who" className="relative -top-28" />
          <About />
         
         
        </div>
        <a id="contact" className="relative -top-28" />
        <Footer />
      </div>
    </Layout>
  );
}

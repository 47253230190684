import React from "react";
import SKGLogo from "../assets/logos/skg.svg";
import Close from "../assets/icons/close.svg";

type FullNavbarMobileType = {
  toggleNavbar: () => void;
  telephone: string;
  visible?: boolean;
};

export default function FullNavbarMobile({
  toggleNavbar,
  telephone,
  visible = false,
}: FullNavbarMobileType) {
  if (!visible) {
    return null;
  }
  return (
    <div className="h-screen bg-dark-blue p-5 transition-height">
      <div className="flex flex-col h-full items-start">
        <SKGLogo className="w-18 h-18 mb-10" />
        <Close
          className="absolute w-5 h-5 right-5 top-10"
          onClick={toggleNavbar}
        />
        <div className="flex flex-col space-y-12 font-bold text-white text-3xl">
          <a href="#about" onClick={toggleNavbar}>
            About Us
          </a>
          <a href="#services" onClick={toggleNavbar}>
            Services
          </a>
          <a href="#contact" onClick={toggleNavbar}>
            Contact Us
          </a>
        </div>
        <a
          href={`tel:${telephone}`}
          className="text-gray mt-auto mb-14 border rounded-full py-2 px-5 border-gray"
        >
          {telephone}
        </a>
      </div>
    </div>
  );
}

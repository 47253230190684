import React from "react";
import Ellipse from "../assets/icons/ellipse.svg";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, type ImageDataLike } from "gatsby-plugin-image";

type ServiceInterface = {
  title: string;
  descriptions: string[];
  img: ImageDataLike;
  imgAlt: string;
};

function Service({ title, descriptions, img, imgAlt }: ServiceInterface) {
  const image = getImage(img);
  return (
    <div className="rounded-xl overflow-hidden">
      <GatsbyImage
        alt={imgAlt}
        image={image!}
        className="w-full h-48 object-cover"
      />
      <div className=" bg-dark-blue  text-white">
        <div className="flex flex-col items-center p-10">
          <h1 className="text-xl mb-5 font-bold">{title}</h1>
          {descriptions.map((description, index) => {
            return (
              <div key={index}>
                <p className="">{description}</p>
                {index + 1 !== descriptions.length && (
                  <Ellipse className="my-3 mx-auto" />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

type Service = {
  id: string;
  title: string;
  descriptions: string[];
  img: ImageDataLike;
  imgAlt: string;
};

export default function OurServices() {
  const query = graphql`
    query myQuery {
      allResourcesJson {
        nodes {
          descriptions
          imgAlt
          id: jsonId
          title
          img {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `;

  const response = useStaticQuery(query);
  const data: Service[] = response.allResourcesJson.nodes;

  return (
    <div className="py-10 mb-10">
      <p className="text-center text-5xl font-semibold text-dark-blue mb-5 break-words">
        Our Services
      </p>
      <div className="md:grid md:grid-cols-3 md:gap-2 space-y-5 md:space-y-0">
        {data.map((service) => (
          <Service
            key={service.id}
            title={service.title}
            descriptions={service.descriptions}
            img={service.img}
            imgAlt={service.imgAlt}
          />
        ))}
      </div>
    </div>
  );
}

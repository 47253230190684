import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function Heading() {
  return (
    <div className="bg-dark-blue">
      <div className="h-screen max-h-[1080px] flex mx-auto  max-w-[1920px]">
        <div className="flex-grow flex flex-col md:grid md:grid-cols-2">
          <div className="flex-grow relative overflow-hidden">
            <StaticImage
              src="../assets/images/cytonn-photography-n95VMLxqM2I-unsplash.jpg"
              alt="Shaking two hands"
              className="h-full md:w-full w-11/12 rounded-xl md:rounded-none object-cover"
            />
            <div className="md:hidden block absolute h-1/2 w-11/12 bg-black/20 bottom-0 blur-md" />
            <div className="text-white text-4xl md:hidden block absolute bottom-5 left-5">
              <p>
                Think <span className="font-bold">Big</span>
              </p>
              <p className="font-medium italic">Scale Fast</p>
              <p className="font-light">Start Small</p>
            </div>
          </div>
          <div className="md:my-auto space-y-5 p-5 mb-24">
            <div className="text-white md:text-6xl hidden md:block">
              <p>
                Think <span className="font-bold">Big</span>
              </p>
              <p className="font-medium italic">Scale Fast</p>
              <p className="font-light">Start Small</p>
            </div>
            <p className="text-white text-xl break-words">
              Founding your company in UAE – Smith Klein Group your partner for
              business establishment
            </p>
            <div className="flex flex-col md:flex-row md:space-x-5 text-white">
              <a
                className="bg-light-blue text-center py-3 px-12 rounded-xl mb-5 md:mb-0 shadow-button"
                href="#contact"
              >
                Get in Touch
              </a>
              <a className="py-3 text-center" href="#services">
                See Our Services
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

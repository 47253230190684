import React from "react";

type HeaderType = {
  content: string;
  extraClass?: React.HTMLAttributes<HTMLHeadElement>["className"];
};

export default function Header({ content, extraClass = "" }: HeaderType) {
  return (
    <h1
      className={
        "text-dark-blue text-5xl font-semibold mb-5 break-words " + extraClass
      }
    >
      {content}
    </h1>
  );
}
